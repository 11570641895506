<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="70%"
    title="tet"
    class="achievements-dialog"
  >
    <div
      slot="title"
      class="d-flex align-items-center custom-title-wrap"
    >
      <span class="el-dialog__title">Achievements</span>
      <button
        class="btn-dialog-close"
        @click="dialogVisible=false"
      >
        <vsvg-icon
          data="@assetsIcon/close-icon.svg"
          scale="1"
          class="close-icon"
          :color="`${brand.styles.primary} #ffffff`"
        />
      </button>
    </div>
    <el-row :gutter="32">
      <el-col
        v-for="(achievement, index) in achievements"
        :key="index"
        :xl="6"
        :lg="6"
        :md="12"
      >
        <achievement-item
          :id="achievement.id"
          :badge="achievement.large_thumb"
          :name="achievement.name"
          :completed-at="achievement.completed_at"
          :is-shared="!!achievement.is_shared"
          :is-shareable="achievement.is_shareable"
          @shared="achievement.is_shared = true"
        />
      </el-col>
    </el-row>
    <el-button
      v-show="hasMoreItems && !loading"
      class="btn-load-more"
      @click="fetch({page: meta.current_page + 1})"
    >
      Load more
    </el-button>
    <div
      v-show="loading"
      v-loading="loading"
      class="loader"
    >
    </div>
  </el-dialog>
</template>

<script>
import AchievementItem from './achievement-item'
import accountApi from '@/modules/account/services/account.api'

export default {
  name: 'AchievementDialog',
  components: {
    AchievementItem
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      hasMoreItems: true,
      achievements: [],
      meta: {
        current_page: 1,
        per_page: 10,
      },
    }
  },
  mounted() {
    this.$events.listen('showAchievementsDialog', (eventData) => {
      this.dialogVisible = true
      if (this.achievements.length == 0) {
        this.fetch()
      }
    })
  },
  methods: {
    fetch(customProperties = {}) {
      this.loading = true
      const properties = {
        page: this.meta.current_page,
        per_page: this.meta.per_page
      };
      accountApi
        .achievements(_.assign(properties, customProperties))
        .then((response) => {
          this.loading = false
          this.achievements.push(...response.data.data)

          this.meta.current_page = response.data.meta.pagination.current_page
          this.meta.per_page = response.data.meta.pagination.per_page

          if (this.meta.current_page == response.data.meta.pagination.total_pages) {
            this.hasMoreItems = false
            return 
          }
        })
        .catch((error) => {
          this.loading = false
          this.$notify.error({
            title: 'Error',
            message: 'An unknown error occurred. Please try again later.',
          })
        })
    },
  }
}
</script>


<style lang="scss">
.achievements-dialog {
    .loader {
        min-height: 100px;
    }
    .el-dialog {
        border-radius: 25px;
    }
    .custom-title-wrap {
         .el-dialog__title {
            font-size: 18px;
            font-weight: bold;
        }
        .btn-dialog-close {
            margin-left: auto;
            background: none;
        }
    }
    .el-dialog__header {
        padding: 40px 70px 10px;
        .el-icon-close {
            display: none;
        }
    }
    .el-dialog__body {
        padding: 30px 70px;
    }
    .btn-load-more {
        display: block;
        margin: 10px auto;
        border: 1px solid #0E147C;
        color: #0E147C;
        font-size: 14px;
    }
}
</style>
