<template>
  <div
    v-loading="loading"
    class="achievement-item"
  >
    <span
      v-if="!isShared && isShareable"
      class="btn-share"
      @click="share"
    >
      <vsvg-icon
        data="@assetsIcon/sm-share-icon.svg"
        scale="1"
        :color="brand.styles.primary"
      />
    </span>
    <img :src="badge" />
    <p class="name">
      {{ name }}
    </p>
    <p class="date-shared">
      {{ completedAt }} <span v-if="completedAt">•</span> {{ isShared ? 'Public' : 'Private' }}
    </p>
  </div>
</template>

<script>
import accountApi from '@/modules/account/services/account.api'

export default {
  name: 'AchievementItem',

  props: {
    id: {
      type: Number,
      required: true,
    },
    badge: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    completedAt: {
      type: String,
      default: '',
    },
    isShared: {
      type: Boolean,
      default: false,
    },
    isShareable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false
    }
  },

  methods: {
    share() {
      this.loading = true

      accountApi
        .shareAchievement(this.id)
        .then((response) => {
          this.loading = false

          this.$emit('shared')

          this.$notify.success({
            title: 'Success',
            message: 'Achievement has been successfully shared on your Feed.',
          })
        })
        .catch((error) => {
          this.loading = false

          this.$notify.error({
            title: 'Error',
            message: 'An unknown error occurred. Please try again later.',
          })
        })
    }
  }
}
</script>

<style lang="scss">
    .achievement-item {
        margin-bottom: 32px;
        text-align: center;
        background: #EDECED;
        border-radius: 10px; 
        padding: 15px;
        position: relative;
        .btn-share {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }
        img {
            width: 70px;
            height: 70px;
        }
        p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 5px 0 0;
            font-size: 14px;
            font-weight: 700;
            color: #373A36;
            &.date-shared {
                font-size: 12px;
                color: #B3B3B3;
            }
        }
        .el-loading-mask {
            background: rgba(255,255,255,.9);
        }
    }
</style>
