<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="70%"
    title="tet"
    class="points-log-dialog"
  >
    <div
      slot="title"
      class="d-flex align-items-center custom-title-wrap"
    >
      <span class="el-dialog__title">Points log</span>
      <button
        class="btn-dialog-close"
        @click="dialogVisible=false"
      >
        <vsvg-icon
          data="@assetsIcon/close-icon.svg"
          scale="1"
          class="close-icon"
          :color="`${brand.styles.primary} #ffffff`"
        />
      </button>
    </div>
    <div class="total-points-wrap">
      <p>Total points since joining:</p>
      <h2>{{ totalPoints }}</h2>
      <span class="pts">pts</span>
    </div>

    <ul 
      v-for="(header, index) in headers"
      :key="index"
      class="point-item-list"
    >
      <li class="month-header-item">
        <p class="month">
          {{ header.label }}
        </p>
        <p class="total">
          {{ header.total }} pts
        </p>
      </li>
      <li
        v-for="(entry, entryIndex) in header.entries"
        :key="entryIndex"
        class="point-item"
      >
        <dynamic-icon-loader
          :path="`point-log/${entry.action_type}-icon.svg`"
          scale="1"
          :color="brand.styles.primary"
        />
        <span class="title">{{ entry.title }}</span>
        <p class="earned">
          {{ entry.points_earned }} pts
        </p>
      </li>
    </ul>
    <button
      v-show="hasMoreItems && !loading"
      class="fgh-button fgh-button--link btn-load-more"
      @click.prevent="fetch({page: meta.current_page + 1})"
    >
      Load more
    </button>
    <div
      v-show="loading"
      v-loading="loading"
      class="loader"
    >
    </div>
  </el-dialog>
</template>

<script>
import moment from 'moment'
import _ from 'lodash'
import DynamicIconLoader from '@/services/dynamic-icon-loader'
import accountApi from '@/modules/account/services/account.api'

export default {
  name: 'PointLogDialog',

  components: {
    DynamicIconLoader
  },

  data: () => ({
    dialogVisible: false,
    loading: false,
    hasMoreItems: true,
    points: [],
    headers: [],
    totalPoints: 0,
    meta: {
      current_page: 1,
      per_page: 10,
    },
  }),

  mounted() {
    this.$events.listen('showPointLogDialog', (eventData) => {
      this.dialogVisible = true
      if (this.points.length == 0) {
        this.fetch()
      }
    })
  },

  methods: {
    fetch(customProperties = {}) {
      this.loading = true
      const properties = {
        page: this.meta.current_page,
        per_page: this.meta.per_page,
        order_by: 'created_at',
        order: 'desc'
      };

      accountApi
        .points(_.assign(properties, customProperties))
        .then((response) => {
          this.loading = false
          this.points = response.data.data
          this.totalPoints = response.data.meta.total_points

          this.meta.current_page = response.data.meta.pagination.current_page
          this.meta.per_page = response.data.meta.pagination.per_page

          if (this.points.length == 0) {
            this.hasMoreItems = false
            return 
          }

          this.updateHeaders(response.data.meta.total_points_by_month)
          this.addPointsToHeaders()
        })
        .catch((error) => {
          this.loading = false
          this.$notify.error({
            title: 'Error',
            message: 'An unknown error occurred. Please try again later.',
          })
        })
    },
    updateHeaders(months) {
      for (const key in months) {
        let header = this.headers.find(header => header.month == key)
        if (!header) {
          const monthYear = moment(key, 'MM-YYYY')
          let label = monthYear.format('MMMM YYYY')

          if (monthYear.isSame(moment(), 'month')) {
            label = 'This month'
          } else if (monthYear.isSame(moment().subtract(1, 'months'), 'month')) {
            label = 'Previous month'
          }

          this.headers.push({
            month: key,
            monthYear: monthYear,
            label: label,
            total: months[key],
            entries: []
          })
        }
      }
    },
    addPointsToHeaders() {
      this.points.forEach((point) => {
        let dateEarned = moment(point.date_earned)
        let header = this.headers.find(header => header.monthYear.isSame(dateEarned, 'month'))
        if (header) {
          header.entries.push(point)
        }
      })
    }
  }
}
</script>

<style lang="scss">
.points-log-dialog {
    .loader {
        min-height: 100px;
    }
    .el-dialog {
        border-radius: 25px;
    }
    .custom-title-wrap {
         .el-dialog__title {
            font-size: 18px;
            font-weight: bold;
        }
        .btn-dialog-close {
            margin-left: auto;
            background: none;
        }
    }
    .el-dialog__header {
        padding: 40px 70px 10px;
        .el-icon-close {
            display: none;
        }
    }
    .el-dialog__body {
        padding: 30px 70px;
    }
    .total-points-wrap {
        display: flex;
        align-items: center;

        p {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
        }

        h2 {
            font-size: 28px;
            margin: 0 10px 0 auto;
            color: $primary-color;
            
        }
        .pts {
            font-size: 12px;
            font-weight: 500;
        }
    }
    .point-item-list {
        padding: 0;
        list-style: none;

        .month-header-item {
            display: flex;
            align-items: center;
            background: #ECECEC;
            margin: 10px 0;
            padding: 15px 30px;

            p {
                font-size: 14px;
                margin: 0;
                font-weight: 700;
                &.month {
                    color: $primary-color;
                }
                &.total {
                    font-size: 16px;
                    margin-left: auto;
                    color: $primary-color;
                }
            }
        }

        .point-item {
            display: flex;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid $border-color;
            margin: 0 26px;
            &:last-child {
                border-bottom: none;
            }

            .title {
                margin-left: 10px;
            }

            .earned {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
                margin-left: auto;
            }
        }
    }

    .btn-load-more {
        display: block;
        margin: 10px auto;
        font-size: 14px;
    }
}
</style>
