<template>
  <div class="profile-page">
    <div class="filter">
      <div class="container">
        <ul>
          <li>Profile</li>
        </ul>
      </div>
    </div>
    <div class="main-info">
      <div class="container">
        <el-row>
          <el-col
            :xl="12"
            :lg="12"
            :md="24"
            :sm="24"
            :xs="24"
          >
            <div class="name-and-more">
              <div
                class="user-img"
                :style="{ 'background-image' : `url( ${ user.image } )` }"
              >
                <div
                  v-if="user.teamLeader"
                  class="shield-icon-wrapper"
                >
                  <vsvg-icon
                    data="@assetsIcon/shield-icon.svg"
                    scale="1"
                    original
                  />
                </div>
              </div>
              <div class="profile-info-wrapper">
                <div class="name-likes-wrapper">
                  <h2 class="name">
                    {{ user.name }}
                  </h2>
                  <div class="likes-count">
                    <vsvg-icon
                      data="@assetsIcon/brand/like-icon.svg"
                      scale="1"
                      :color="brand.styles.primary"
                    />
                    {{ user.total_likes }}
                  </div>
                </div>
                <div class="team-label-wrapper">
                  <p class="team">
                    Team: {{ user.team }}
                  </p>
                  <div
                    v-show="!isLoading"
                    class="label"
                    :class="{ leader: user.teamLeader, supporter: !user.teamLeader }"
                  >
                    <vsvg-icon
                      v-if="user.teamLeader"
                      data="@assetsIcon/shield-icon.svg"
                      width="14"
                      height="18"
                      original
                    />
                    {{ user.teamLeader ? 'Champion' : user.groupLabel }}
                  </div>
                </div>
                <div class="follow-counts-wrapper">
                  <p class="count">
                    <span>{{ user.followers_count }}</span> Followers
                  </p>
                  <p class="count">
                    <span>{{ user.following_count }}</span> Following
                  </p>
                </div>
              </div>
            </div>
          </el-col>
          <el-col
            :xl="12"
            :lg="12"
            :md="24"
            :sm="24"
            :xs="24"
            class="offer-links"
          >
            <el-col
              :xl="24"
              :lg="24"
              :md="24"
              :sm="24"
              :xs="24"
              class="easier-uploads-and-invite-colleagues"
            >
              <div class="easier-uploads">
                <p>{{ stravaStatusText }}</p>
                <div
                  v-if="isLoading || stravaIsLoading"
                  v-loading="isLoading || stravaIsLoading"
                  class="strava-loading"
                ></div>

                <a
                  v-if="!user.stravaAuthorized && !isLoading && !stravaIsLoading"
                  :href="stravaAuthorizeUrl"
                  tag="button"
                  class="strava"
                >
                  <vsvg-icon
                    data="@assetsIcon/connect-strava-icon.svg"
                    scale="1"
                    original
                  />
                </a>
                <button
                  v-if="user.stravaAuthorized && !isLoading && !stravaIsLoading"
                  tag="button"
                  class="strava"
                  @click="deauthorizeStrava"
                >
                  <vsvg-icon
                    data="@assetsIcon/disconnect-strava-icon.svg"
                    scale="1"
                    original
                  />
                </button>
              </div>
              <div class="invite-colleagues">
                <p>Encourage someone</p>
                <router-link
                  to="#"
                  tag="button"
                  class="fgh-button fgh-button--primary btn-invite"
                  @click.native="openInviteForm"
                >
                  {{ brand.lang.profileInviteButtonText }}
                </router-link>
              </div>
              <transition name="fade">
                <div
                  v-if="inviteColleague"
                  v-on-click-outside="closeInviteForm"
                  class="invite-colleague-form"
                >
                  <div class="invite-colleague-title">
                    <h3>{{ brand.lang.profileInvitePopoverText }}</h3>
                    <p @click="inviteColleague = false">
                      <vsvg-icon
                        data="@assetsIcon/close-icon.svg"
                        scale="1"
                        class="close-icon"
                        :color="`${brand.styles.primary} #ffffff`"
                      />
                    </p>
                  </div>
                  <div class="form-invite">
                    <el-form
                      id="inviteForm"
                      ref="inviteForm"
                      :model="inviteForm"
                      :rules="rules"
                    >
                      <el-form-item
                        label="Email of person you want to invite"
                        prop="email"
                      >
                        <el-input
                          v-model="inviteForm.email"
                          type="email"
                          placeholder="Enter email address"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <button
                          type="submit"
                          class="fgh-button fgh-button--primary"
                          @click.prevent="onSubmit"
                        >
                          Send
                        </button>
                      </el-form-item>
                    </el-form>
                  </div>
                </div>
              </transition>
            </el-col>
          </el-col>
        </el-row>
        <div class="points-and-links">
          <el-row>
            <el-col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
              :xs="24"
            >
              <div class="points-wrap d-flex align-items-center">
                <div class="circle">
                  <vsvg-icon
                    data="@assetsIcon/star-icon.svg"
                    width="14"
                    height="14"
                    :color="brand.styles.primary"
                  />
                </div>
                <h1>{{ user.total_points ? user.total_points : 0 }}</h1>
                <span>Points</span>
              </div>
            </el-col>
            <el-col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
              :xs="24"
            >
              <button
                class="fgh-button fgh-button--link pull-right"
                @click.prevent="viewPointLogDialog"
              >
                View points log
              </button>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <div class="points-info">
      <div class="container">
        <el-row :gutter="30">
          <el-col
            v-for="(statValue, statKey) in user.activity_stats"
            :key="statKey"
            :xl="6"
            :lg="6"
            :md="6"
          >
            <activity-stat-card
              v-if="statKey != 'frequency-challenge_logged' && statKey != 'ride_logged' && statKey != 'run_logged' && statKey != 'walk_logged' && statKey != 'swim_logged'"
              :stat-key="statKey"
              :stat-value="statValue"
              :is-loading="isLoading"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="achievements-block">
      <div class="container">
        <h2>Achievements ({{ user.totalAchievements }})</h2>
        <el-button
          v-if="user.totalAchievements > 0"
          type="text"
          class="fgh-button fgh-button--link pull-right"
          @click="viewAchievementsDialog"
        >
          View all
        </el-button>
        <transition name="fadeLoading">
          <div
            v-if="achievementsLoading"
            v-loading="achievementsLoading"
            class="container"
            style="min-height: 90px"
          ></div>
        </transition>
        <div
          v-if="user.achievements && !achievementsLoading"
          class="achievements"
        >
          <el-row>
            <el-col
              v-for="achievement in user.achievements"
              :key="achievement.id"
              :xl="6"
              :lg="6"
              :md="12"
            >
              <div class="achievement-block">
                <img :src="achievement.large_thumb" />
                <div class="achievement-details">
                  <p>{{ achievement.name }}</p>
                  <p>{{ achievement.completed_at }} <span v-if="achievement.completed_at">•</span> {{ achievement.is_shared ? 'Public' : 'Private' }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
        <div
          v-if="!user.achievements.length && !achievementsLoading"
          class="no-achievements"
        >
          <div>
            <h2>You've no achievements yet</h2>
            <p>You’ll gain badges once you’ve completed a challenge</p>
          </div>
        </div>
      </div>
    </div>
    <div class="unlocked-challenge">
      <div class="container">
        <transition name="fadeLoading">
          <div
            v-if="rewardsLoading"
            v-loading="rewardsLoading"
            class="container"
            style="min-height: 90px"
          ></div>
        </transition>
        <h2>Member offers</h2>
        <div
          v-if="user.rewards && !rewardsLoading"
          class="rewards"
        >
          <el-row :gutter="30">
            <el-col
              v-for="(reward, i) in user.rewards"
              :key="`reward-${i}`"
              :xl="6"
              :lg="8"
              :md="12"
              :sm="12"
              :xs="24"
              class="el-col-rewards"
            >
              <router-link :to="{ name: 'feed-reward', params: { id: reward.id } }">
                <offer-card
                  :title="reward.title"
                  :media="reward.mediaFile.is_image? reward.mediaFile.large_thumb: reward.mediaFile.video_thumb"
                  :is-image="reward.mediaFile.is_image"
                />
              </router-link>
            </el-col>
          </el-row>
        </div>
        <div
          v-if="!user.rewards.length && !rewardsLoading"
          class="no-rewards"
        >
          <div>
            <h2>No member offers available right now</h2>
            <p>Check back later</p>
          </div>
        </div>
      </div>
    </div>
    <point-log-dialog />
    <achievements-dialog />
  </div>
</template>

<script>
import OfferCard from '@/modules/feed/components/offer-card'
import { mixin as onClickOutside } from 'vue-on-click-outside'
import ActivityStatCard from '@/modules/account/components/activity-stat-card'
import PointLogDialog from '@/modules/account/components/point-log-dialog'
import AchievementsDialog from '@/modules/account/components/achievements-dialog'
import accountApi from '@/modules/account/services/account.api'

export default {
  name: "ProfilePage",

  components: {
    OfferCard,
    ActivityStatCard,
    PointLogDialog,
    AchievementsDialog
  },

  mixins: [onClickOutside],

  data() {
    let validateEmail = (rule, value, callback) => {
      let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (value === '') {
        callback(new Error('Please input your email'));
      } else if (reg.test(value) == false) {
        callback(new Error('The email field must be a valid email address'));
      } else {
        callback();
      }
    };
    return {
      inviteColleague: false,
      isLoading: false,
      stravaIsLoading: false,
      achievementsLoading: false,
      rewardsLoading: false,
      inviteForm: {
        email: ''
      },
      rules: {
        email: [
          { validator: validateEmail, required: true }
        ]
      },
      user: {
        name: `${this.$store.state.firstName} ${this.$store.state.lastName}`,
        image: `${this.$store.state.avatar}`,
        team: `${this.$store.state.teamVal}`,
        stravaAuthorized: this.$store.state.stravaAuthorized,
        activity_stats: null,
        achievements: [

        ],
        totalAchievements: 0,
        rewards: []
      },
      stravaAuthorizeUrl: process.env.VUE_APP_STRAVA_AUTHORIZE_URL
    }
  },

  computed: {
    stravaStatusText() {
      if (this.isLoading || this.stravaIsLoading) {
        return 'Loading...'
      }

      return this.user.stravaAuthorized ? 'Connected' : 'Want easier uploads?'
    },
  },

  created() {
    this.getProfile()
    this.getAchievements()
    this.getRewards()
  },

  mounted() {
    if (this.$route.query.code && this.$route.query.code != "") {
      this.authorizeStrava(this.$route.query.code)
    }
  },

  methods: {
    getProfile() {
      this.isLoading = true

      accountApi
        .profile()
        .then((response) => {
          this.isLoading = false
          this.user.teamLeader = response.data.team && response.data.team.is_leader
          this.user.groupLabel = response.data.group_label
          this.user.stravaAuthorized = response.data.strava_authorized
          this.user.activity_stats = response.data.activity_stats
          this.user.total_likes = response.data.total_likes
          this.user.total_points = response.data.total_points
          this.user.following_count = response.data.following_count
          this.user.followers_count = response.data.followers_count
        })
    },
    getAchievements() {
      this.achievementsLoading = true
      accountApi
        .achievements({
          per_page: 4,
          page: 1
        })
        .then((response) => {
          this.achievementsLoading = false
          this.user.achievements = response.data.data;
          this.user.totalAchievements = response.data.meta.pagination.total
        })
    },
    getRewards() {
      this.rewardsLoading = true
      accountApi
        .rewards({
          per_page: 20,
          page: 1
        })
        .then((response) => {
          this.rewardsLoading = false
          this.user.rewards = response.data.data;
        })
    },
    viewPointLogDialog() {
      this.$events.emit('showPointLogDialog')
    },
    viewAchievementsDialog() {
      this.$events.emit('showAchievementsDialog')
    },
    openInviteForm() {
      this.inviteColleague = true;
    },
    closeInviteForm() {
      if(this.inviteColleague) {
        this.inviteColleague = false;
      }else {
        this.inviteColleague = true;
      }
    },
    onSubmit() {
      this.$refs.inviteForm.validate((valid) => {
        if (valid) {
          this.isLoading = true
          accountApi
            .invite(this.inviteForm.email)
            .then((response) => {
              this.isLoading = false;
              this.inviteForm.email = ''
              this.inviteColleague = false
              if (response.data.message !== '') {
                this.$notify.success({
                  title: 'Success',
                  message: response.data.message
                })
              }
            }).catch(error => {
              this.isLoading = false
              this.inviteForm.email = ''
              this.inviteColleague = false

              this.$notify.error({
                title: 'Error',
                message: error.response.status == 422 ? error.response.data.errors.email[0] : 'An unexpected error occured. Please try again later'
              })
            })
        }
      })
    },
    authorizeStrava(code) {
      this.stravaIsLoading = true

      accountApi
        .strava
        .authorize(code)
        .then((response) => {
          this.stravaIsLoading = false
          this.user.stravaAuthorized = response.data.authorized
        })
    },
    deauthorizeStrava() {
      this.stravaIsLoading = true

      accountApi
        .strava
        .deauthorize()
        .then((response) => {
          this.stravaIsLoading = false
          this.user.stravaAuthorized = response.data.authorized
        })
    }
  }
}
</script>
<style lang="scss" scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .fadeLoading-enter-active {
        transition: ease-in opacity 2s;
    }
    .fadeLoading-enter, .fadeLoading-leave-to /* .fadeLoading-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>


<style lang="scss">
.profile-page {
  .filter {
    height: 55px;
    background-color: $feed-banner-primary-bg-color;
    ul {
      list-style: none;
      margin: 0;
      padding-left: 0;
      padding-top: 11.5px;
      li {
        display: inline-block;
        font-size: 14px;
        background-color: $feed-banner-primary-bg-active-color;
        color: $feed-banner-primary-fg-active-color;
        font-weight: bold;
        padding: 7.5px 33px;
        border-radius: 16.5px;
        margin-right: 12px;
        cursor: pointer;
        transition: .3s ease-in;
        @media (max-width: 767px) {
          padding: 7.5px 22px;
          border-radius: 16.5px;
          margin-right: 6px;
        }
        @media (max-width: 575px) {
          font-size: 12px;
          padding: 8.5px 1px;
          margin-right: 0px;
          width: 23%;
          text-align: center;
        }
      }
    }
  }
  .main-info {
    background-color: $white-color;
    padding: 32px 0;
    .el-row {
      display: flex;
      align-items: center;
      @media (max-width: 991px) {
        display: block;
      }
    }
    .name-and-more {
      display: flex;
      .user-img {
        background-position: center center;
        background-size: cover;
        position: relative;
        width: 96px;
        height: 96px;
        border-radius: 50%;
        position: relative;
        @media (max-width: 425px) {
          margin: 0 auto;
        }
        .shield-icon-wrapper {
          position: absolute;
          right: -6px;
          bottom: -6px;
          z-index: 10;
          display: flex;
          background: $white-color;
          align-items: center;
          justify-content: center;
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
      }
      .profile-info-wrapper {
        margin-left: 30px;

        .name-likes-wrapper, .team-label-wrapper {
          display: flex;
          align-items: center;
        }

        .name-likes-wrapper {
          margin-top: 5px;
          .name {
            margin: 0;
            font-size: 28px;
          }
          .likes-count {
            margin-left: 20px;
            border: 1px solid $border-color;
            border-radius: 16px;
            padding: 4px 14px;
            display: flex;
            align-items: center;
            svg {
              margin-right: 10px;
            }
          }
        }

        .team-label-wrapper {
          font-size: 14px;
          font-weight: bold;
          margin-top: 5px;
          .team {
            margin: 0 20px 0 0;
          }
          .label {
            display: flex;
            align-items: center;
            margin-left: 0;
            position: relative;
            &:before {
              content: '|';
              position: absolute;
              left: -10px;
              color: $body-text-color;
            }
            &.supporter {
              color: #AA5D37;
            }
            &.leader {
              color: #DB8000;
            }
            svg {
              margin-right: 5px;
            }
          }
        }
        .follow-counts-wrapper {
          display: flex;
          align-items: center;
          .count {
            font-size: 13px;
            position: relative;
            &:last-child{
              margin-left: 20px;
              &:before {
                content: '|';
                position: absolute;
                left: -10px;
                color: $body-text-color;
              }
            }
            span {
              color: $primary-color;
            }
          }
        }
      }
    }
    .points-and-links {
      margin-top: 30px;
      background: $white-color;
      border-radius: 10px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05), 0px 0px 21px rgba(0, 0, 0, 0.07);
      padding: 20px;
      .points-wrap {
        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          border: 2px solid $primary-color;
          width: 26px;
          height: 26px;
          border-radius: 50%;
        }
        h1 {
          margin: 0 0 0 20px;
          font-size: 68px;
          font-weight: 700;
        }
        span {
          margin-left: 25px;
        }
      }
    }
    .offer-links {
      text-align: right;
      @media (max-width: 768px) {
        margin-top: 15px;
        text-align: center;
      }
      .easier-uploads-and-invite-colleagues {
        position: relative;
        @media (max-width: 768px) {
          padding-right: 0;
        }
        .easier-uploads, .invite-colleagues {
          display: inline-block;
          text-align: left;
          .strava-loading {
            margin-top: 10px;
            padding: 12px 9px;
            display: inline-block;
            .el-loading-spinner { margin-top: -13px; }
          }
          @media (max-width: 768px) {
            text-align: center;
          }
          @media (max-width: 425px) {
            margin-bottom: 15px;
          }
          p {
            margin: 0;
            font-size: 12px;
            font-weight: bold;
          }
        }
        .invite-colleagues {
          padding-left: 30px;
          @media (max-width: 375px) {
            padding-left: 0;
          }
        }
        .invite-colleague-form-content {
          z-index: 2;
        }
        .invite-colleague-form {
          position: absolute;
          top: 81px;
          right: 30px;
          width: max-content;
          z-index: 2;
          padding: 15px 20px;
          background: #FFFFFF;
          box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.280797);
          border-radius: 6px;
          @media (max-width: 768px) {
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, 0%);
          }
          @media (max-width: 425px) {
            transform: translate(-30%, 0%);
          }
          @media (max-width: 375px) {
            transform: translate(-40%, 0%);
          }
          @media (max-width: 320px) {
            transform: translate(-50%, 40%);
          }
          &:after {
            content: "";
            position: absolute;
            top: -10px;
            right: 70px;
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #FFFFFF;
            @media (max-width: 320px) {
              left: 50%;
              transform: translate(-50%, 0%);
            }
          }
          .invite-colleague-title {
            overflow: auto;
            h3 {
              float: left;
              margin: 0;
              line-height: 24px;
            }
            p {
              float: right;
              margin: 0;
              cursor: pointer;
              i {
                color: $primary-color;
                font-size: 20px;
              }
            }
          }
          .form-invite {
            .el-form-item {
              display: inline-block;
              //margin: 0;
              @media (max-width: 425px) {
                &:last-child {
                  display: block;
                  button {
                    margin-left: 0;
                  }
                }
              }
              .el-form-item__label {
                &:before {
                  display: none;
                }
              }
              .el-input {
                input {
                  max-width: 350px;
                  width: 250px;
                }
              }
              .el-icon-loading {
                margin-left: -28px !important;
                margin-right: 10px !important;
              }
              button {
                padding: 12px 35px;
                margin-left: 15px;
              }
            }
          }
        }
        button, a {
          font-size: 14px;
          margin-top: 11px;
          font-weight: bold;
          &.btn-invite {
            padding: 0 50px;
            height: 40px;
          }
          &.strava {
            display: block;
            padding: 0;
            svg {
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
  .points-info {
    padding: 30px 0px 32px 0;
    .el-loading-parent--relative {
      width: auto;
    }
    .total-points {
      @media (max-width: 991px) {
        margin: 0 auto 30px auto;
        float: none;
      }
    }
    .white-card {
      background-color: $white-color;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08155);
      border-radius: 10px;
      overflow: hidden;
      p {
        margin: 0;
      }
      .title {
        text-align: center;
        p {
          font-size: 16px;
          margin-top: 5px;
        }
      }
    }
    .little-card {
      margin-bottom: 30px;
      padding: 15px 20px;
      text-align: center;
      .red-icon {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        svg {
          margin-right: 20px;
          min-height:30px;
        }
      }
      .title {
        font-size: 14px;
      }
      .count {
        display: flex;
        justify-content: space-between;
        font-size: 42px;
      }
      .cat {
        font-size: 13px;
        font-weight: bold;
        color: #4A4A4A;
        margin-top: auto;
      }
    }
  }
  .achievements-block {
    background-color: $white-color;
    padding: 24px 0 0 0;
    h2 {
      display: inline-block;
      @media (max-width: 425px) {
        text-align: center;
      }
    }
    .achievements {
      margin-top: 23px;
      @media (max-width: 425px) {
        text-align: center;
      }
    }
    .achievement-block {
      margin: 0 20px 40px 0;
      display: flex;
      align-items: center;

      img {
        width: 50px;
        height: 50px;
      }
      .achievement-details {
        margin-left: 10px;
        p {
          font-size: 13px;
          line-height: 13px;
          margin: 0;
          &:first-child {
            margin: 0 0 5px;
          }
        }
      }
    }
    .no-achievements{
      overflow: auto;
      margin-top: 10px;
      text-align: center;
      h2, p {
        margin: 0;
      }
      p {
        margin: 8px 0 40px 0;
        font-size: 14px;
      }
    }
  }
  .unlocked-challenge {
    padding: 24px 0 0 0;
    h2 {
      margin: 0;
      @media (max-width: 425px) {
        text-align: center;
      }
    }
    .rewards {
      margin-top: 24px;
      .el-col-rewards {
        margin-bottom: 30px;
      }
    }
    .no-rewards {
      margin-top: 60px;
      margin-bottom: 173px;
      text-align: center;
      h2 {
        margin-top: 14px;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
</style>
